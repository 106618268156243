import React from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Search from "../Search";
import Search2 from "../Search2";

function Productosv3() {
    return(
        <>
        <Search2 />
        </>
    );
}

export default Productosv3;