import React from "react";
import Footer from "../Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../pages/Contactos.css';

function Contactos() {

    return (
        <>
            <body class="contact">
                <br/><br/><br/>
                <ul class="descriptions">

                    <li class="description description--placeholder">
                        <h2 class="heading heading--2">Información de contacto</h2>
                    </li>
                </ul>
                <br/><br/><br/>

                <div class="lanes">
                    <ul class="lane">
                        <li class="lane__title"></li>
                        <br /><br /><li class="lane__item"> <a class="direct" href="https://wa.link/byj1si" target="_blank"><FontAwesomeIcon icon={faWhatsapp} size="2xl" /></a></li>
                        <li class="lane__item"> <a class="direct" href="https://www.instagram.com/chengshan.ve/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2xl" /></a></li>
                        <li class="lane__item"> <a class="direct" href="mailto:info@cauchoslamundial.com" target="_blank"><FontAwesomeIcon icon={faEnvelopeOpen} size="2xl" /></a></li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br/>
                </div>
            </body>
            <Footer />

        </>
    );
}

export default Contactos;