import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../comercios/css comercios/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../Footer';

function Comercio_zulia() {
    return (
        <>
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución del Zulia</h2>
                            <br></br><br></br>

                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">RUEDAS LA MUNDIAL 72, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE 72 GILBERTO CORREA, ENTRE AV. 13 Y AV. 12 AL LADO DE FARMACIA FARMAEXPRESS.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584122618392" target="_blank">+58 412-2618392</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaslamundial/?hl=en" target="_blank">@ruedaslamundial</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d980.2100799929663!2d-71.61596608000409!3d10.669499683865592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8999d446d6317d%3A0x6b5846a8095c79d5!2sRuedas%20La%20Mundial%2072%2C%20C.A.!5e0!3m2!1ses!2sve!4v1685629178927!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">RUEDAS LA MUNDIAL CECILIO ACOSTA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. 9B CON CALLE 67 CECILIO ACOSTA.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584122618392" target="_blank">+58 412-2618392</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaslamundial/?hl=en" target="_blank">@ruedaslamundial</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d824.234247697298!2d-71.61340748604994!3d10.677206251225455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e89994e59916691%3A0xfe5933d950b31512!2sRuedas%20La%20Mundial!5e0!3m2!1sen!2sve!4v1685476320688!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">RUEDAS LA MUNDIAL SABANETA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> SECTOR SABANETA, DIAGONAL AL MONUMENTO LA CHINITA DE LA C1.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584122618392" target="_blank">+58 412-2618392</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaslamundial/?hl=en" target="_blank">@ruedaslamundial</a> <br /><br />

                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2772.8260344166465!2d-71.64203876495948!3d10.628478416304889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8999beca66dc79%3A0x36e5800889123875!2sRuedas%20La%20Mundial%20Sabaneta!5e0!3m2!1sen!2sve!4v1685476457134!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">RUEDAS LA MUNDIAL VERITAS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE 90, CON AV. 8 (DIAGONAL A LA ESTACIÓN DE SERVICIO NUEVO CIRCO), LAS VERITAS, MARACAIBO.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584122618392" target="_blank">+58 412-2618392</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaslamundial/?hl=en" target="_blank">@ruedaslamundial</a> <br /><br />

                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1960.5559377343!2d-71.61050540879857!3d10.64841612360719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8999e5e16eec79%3A0xcafe6474ff2d41b3!2sRuedas%20la%20Mundial%20Veritas!5e0!3m2!1sen!2sve!4v1685476504545!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">RUEDAS LA MUNDIAL SUR, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> UBICADA A 200 METROS DE LA ESCUELA DE POLICÍAS DE SAN FRANCÍSCO.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584122618392" target="_blank">+58 412-2618392</a><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ruedaslamundial/?hl=en" target="_blank">@ruedaslamundial</a> <br /><br />

                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2332.1889969281747!2d-71.62410179458313!3d10.558915070637386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e89979e7bbb8271%3A0xd9b6b8ffb13d653a!2sRuedas%20La%20Mundial%20Sur!5e0!3m2!1sen!2sve!4v1685476539488!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">INVERSIONES 3S & N.C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. INDEPENDENCIA LOCAL S/N. A 100 MTS DE CORPOZULIA SECTOR LA FLORIDA PUEBLO NUEVO, ZULIA.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145144649" target="_blank">+58 414-5144649</a><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">AGROTIRE'S VE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                         <FontAwesomeIcon icon={faLocationArrow} /> AV. INTERCOMUNAL SECTOR BELLO MONTE-27 CABIMAS, ZULIA.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> +58 412-6400216.<br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.903223119521!2d-63.547953824072735!3d8.111336102676033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce87baa702d181%3A0x620f49d4a52584b9!2sAuto%20Accesorios%20Don%20Ventura%20C.A!5e0!3m2!1sen!2sve!4v1685449799365!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">UNICAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. INTERCOMUNAL SECTOR BELLO MONTE-27 CABIMAS, ZULIA.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /><a className="contacts" href="tel:+584126400216" target="_blank"> +58 412-6400216</a> <br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.903223119521!2d-63.547953824072735!3d8.111336102676033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce87baa702d181%3A0x620f49d4a52584b9!2sAuto%20Accesorios%20Don%20Ventura%20C.A!5e0!3m2!1sen!2sve!4v1685449799365!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>
                            <br></br><br></br>
                            <br></br><br></br>


                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_zulia;