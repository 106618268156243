import React, { useState } from "react";
import data from './data';
import '../components/Search.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



const Search = () => {
    const [filter, setFilter] = useState('');

    const searchText = (event) => {
        setFilter(event.target.value);
    }
    let dataSearch = [];

    if (filter.trim() !== '') {
        dataSearch = data.cardData.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(filter.toString().toLocaleLowerCase())
            )
        });
    }


    return (
        <>
            <video
                className="fondo"
                id="fondo-productos"
                autoPlay
                loop
                muted
                style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: "-1",
                }}
            >
                <source src="videos/chengshan.webm" type="video/mp4" />
            </video>

            <section className="all py-4 container">

                <div className="row justify-content-center">

                    <div className="col-12 mb-5">
                        <div className="mb-6 col-6 mx-auto text-center">
                            {/* <center > */}
                            <div className="buscador">
                                <label className="form-lable h4"><FontAwesomeIcon icon={faMagnifyingGlass} /> Busca tu vehículo, modelo o medida:</label><br /><br />
                                <input
                                    id="search-bar"
                                    type="text"
                                    className="from-control"
                                    placeholder=" Ejemplo: 195/80R16, Modelo de vehículo, Marca de Vehículo"
                                    value={filter}
                                    onChange={searchText.bind(this)} />
                            </div>
                            {/* </center> */}
                        </div>
                    </div>

                    {dataSearch.length > 0 ? (
                        dataSearch.map((item, index) => {
                            return (
                                <div className="col-11 col-md-6 col-lg-4 mx-0 mb-4">
                                    <div className="card  p-0 overflow-hidden h-100 shadow">

                                        <img src={item.img} className="card-img-top" />

                                        <div className="card-body">

                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-lg-3"></div>
                                                    <center>
                                                        <div class="col-lg-6"><a href={item.url} download className="btn btn-danger btn-product"><FontAwesomeIcon icon={faFileArrowDown} /> Ficha Tecnica</a></div>
                                                    </center>
                                                    <div class="col-lg-3"></div>
                                                </div>
                                            </div>


                                            {/* Modelo de Caucho */}
                                            <h5 className="card-title">{item.title}</h5>

                                            {/* Medidas */}
                                            <p className="card-text" >{item.med}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med1}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med2}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med3}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med4}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med5}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med6}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med7}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med8}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med9}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med10}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med11}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med12}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med13}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med14}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.med15}</p>


                                            {/* Marcas */}
                                            <p className="card-text" style={{ display: "none" }}>{item.mark}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark1}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark2}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark3}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark4}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark5}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark6}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark7}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark8}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark9}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark10}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark11}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark12}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.mark13}</p>


                                            {/* Modelos */}
                                            <p className="card-text" style={{ display: "none" }}>{item.model}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model1}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model2}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model3}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model4}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model5}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model6}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model7}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model8}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model9}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model10}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model11}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model12}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model13}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model14}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model15}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model16}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model17}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model18}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model19}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model20}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model21}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model22}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model23}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model24}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model25}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model26}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model27}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model28}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model29}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model30}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model31}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model32}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model33}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model34}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model35}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model36}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model37}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model38}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model39}</p>
                                            <p className="card-text" style={{ display: "none" }}>{item.model40}</p>


                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    ) : (
                        <div className="nothing col-12 mb-5">
                            {/* <p>No se encontraron productos</p> */}
                        </div>
                    )
                    }

                </div>
            </section>
        </>
    )
}

export default Search;